<template>
  <div v-bind="$props" id="final-page">
    <v-row align="center" justify="center" class="ma-0 text-center pa-0 text-h5 text-lg-h4 text-capitalize primary--text">{{ themeModel.thank_you_banner | textCapts}}</v-row>
    <v-row align="center" justify="center">
        <v-img contain class="ma-8" src="@/assets/icons/success-icon.png" width="90" height="90" />
    </v-row>
    <v-card-title class="text-capitalize primary--text px-0 font-weight-bold justify-center text-center text-subtitle-1 text-lg-h6">We will send you an email to confirm your membership in the next 1-2 business days.</v-card-title>
    <v-row  align="center" justify="center" style="padding-top: 150px">
        <v-col cols="12" class="col-lg-8" align="center" justify="center">
            <v-btn type="button" color="primary" rounded outlined class="font-weight-bold" :disabled="processLoading" @click.prevent="resetForm" >Return to home page</v-btn>
        </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
    name: "FinalStep",
    emits: ["goHome"],
    data() {
        return {
            processPercentage: 85,
            story_details: '',
            themeModel: {
                target_mode: 'P',
                show_like_button: 'On',
                show_target: 'On',
                thank_you_banner: 'Your Membership Payment Has Been Processed'
            }
        };
    },
    computed: {
        ...mapGetters(["processLoading", "themeApiModel", "themeLoading"]),
    },
    watch: {
        themeApiModel: function(newValue) {
            if (newValue) {
                const valueThemeModel = this.$helpers.getValueOnlyObj(newValue);
                this.themeModel = {...this.themeModel, ...valueThemeModel};
            }
        }
    },
    filters: {
        textCapts: function(text) {
            if (text && text.trim() !== '') {
                return text.replace('Thelight', 'TheLight');
            }
            return '';
        },
    },
    created() {
        const valueThemeModel = this.$helpers.getValueOnlyObj(this.themeApiModel);
        this.themeModel = {...this.themeModel, ...valueThemeModel};
    },
    mounted() {
        window. scrollTo(0,0);
    },
    methods: {
        resetForm() {
            this.$helpers.removeLSStorage('curDonate');
            this.$store.commit('resetModel');
            window.location.reload();
        }
    }
};
</script>
